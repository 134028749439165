import type { CheckboxProps } from '@cmg/design-system';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@cmg/design-system';
import { useField } from 'formik';
import type { ChangeEvent } from 'react';
import React, { useCallback, useMemo } from 'react';

export type FormikCheckboxFieldProps<TFormValues extends Record<string, unknown>> = Omit<
  CheckboxProps,
  'name' | 'error' | 'onChange' | 'onBlur' | 'value'
> & {
  readonly name: keyof TFormValues & string;
  readonly label?: React.ReactNode;
  readonly onChange?: (isChecked: boolean) => void;
};

export function FormikCheckboxField<TFormValues extends Record<string, unknown>>({
  name,
  label,
  required,
  onChange,
  ...restProps
}: FormikCheckboxFieldProps<TFormValues>): JSX.Element {
  const [field, meta] = useField<boolean | null | undefined>(name);

  const hasError = meta.touched && Boolean(meta.error);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      field.onChange(e);
      onChange?.(checked);
    },
    [field, onChange]
  );

  const checkboxProps: CheckboxProps = useMemo(
    () => ({
      ...restProps,
      ...field,
      value: field.value ?? false,
      checked: field.value ?? false,
      required,
      onChange: handleChange,
      color: hasError ? 'error' : restProps.color,
      inputProps: {
        ...restProps.inputProps,
        'aria-invalid': hasError,
      },
    }),
    [field, handleChange, hasError, required, restProps]
  );

  return (
    <FormControl required={required} error={hasError} fullWidth={false}>
      {label ? (
        <FormControlLabel label={label} control={<Checkbox {...checkboxProps} />} />
      ) : (
        <Checkbox {...checkboxProps} />
      )}
      {hasError && <FormHelperText error={hasError}>{meta.error}</FormHelperText>}
    </FormControl>
  );
}
