import type { ControlWithHelperTextProps, TextFieldProps } from '@cmg/design-system';
import { ControlWithHelperText, TextField } from '@cmg/design-system';
import { useField } from 'formik';
import type { ChangeEvent } from 'react';
import React, { useCallback } from 'react';

export type FormikTextFieldProps<TFormValues extends Record<string, unknown>> = Omit<
  TextFieldProps,
  'name' | 'value' | 'onChange' | 'error' | 'helperText'
> &
  Pick<ControlWithHelperTextProps, 'showHelperTextInTooltip'> & {
    readonly name: keyof TFormValues & string;
    readonly onChange?: (value: string) => void;
  };

export function FormikTextField<TFormValues extends Record<string, unknown>>({
  name,
  showHelperTextInTooltip,
  onChange,
  ...restProps
}: FormikTextFieldProps<TFormValues>): JSX.Element {
  const [field, meta] = useField<TFormValues>(name);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      field.onChange(e);
      onChange?.(e.target.value);
    },
    [field, onChange]
  );

  return (
    <ControlWithHelperText
      tooltipVariant="error"
      showHelperTextInTooltip={showHelperTextInTooltip}
      helperText={meta.touched && meta.error}
      renderControl={renderControlProps => {
        return (
          <TextField
            {...restProps}
            {...field}
            helperText={renderControlProps.helperText}
            inputProps={{ ...restProps.inputProps, ...renderControlProps.inputProps }}
            onChange={handleChange}
            error={meta.touched && Boolean(meta.error)}
          />
        );
      }}
    />
  );
}
