import { Tag } from '@cmg/common';
import { FormikProps, validateYupSchema, withFormik, yupToFormErrors } from 'formik';
import React from 'react';
import { ObjectSchema } from 'yup';

import {
  SButtonWrapper,
  STableCell,
  STableCellContent,
  STableRow,
  StyledRecordCancelEditIconButton,
  StyledRecordSubmitIconButton,
} from './GenericFirmRecordRow.styles';
import { FirmRecordColDef, FirmRecordRowData } from './GenericPanelTypes';

export type OwnProps<TValues> = {
  isEditable?: boolean;
  columns: FirmRecordColDef<TValues>[];
  row: FirmRecordRowData<TValues>;
  isEditing: boolean;
  isUpdating?: boolean;
  isNewRecord?: boolean;
  onSubmit: (values: TValues) => void;
  onCancelEdit: () => void;
  onEdit?: () => void;
  validationSchema?: ObjectSchema<{}>;
};

type Props<TValues> = OwnProps<TValues> & FormikProps<TValues>;
type GenericFirmRecordRowFC<TValues = any> = React.FC<Props<TValues>>;

/**
 * A component used to render record details rows within the GenericFirmRecordPanel
 * component.
 *
 * @isEditable whether the record/row is editable.
 * @isEditing whether the row should display in edit mode or not
 * @isUpdating whether the record is in the process of updating or not
 * @isNewRecord whether the form is empty initially or not for adding new record
 * @columns the column configuration for the table
 * @row the record object displayed in the row
 * @handleSubmit callback prop that's called when the submit button is clicked (in edit mode)
 * @onEdit callback prop that's called when the row is clicked (to activate edit mode)
 * @onCancelEdit callback prop that's called when the cancel button is clicked (in edit mode)
 */
export const GenericFirmRecordRowComponent: GenericFirmRecordRowFC = props => {
  const {
    isEditable = true,
    isEditing,
    isUpdating,
    isNewRecord,
    columns,
    row,
    handleSubmit,
    onEdit,
    onCancelEdit,
    resetForm,
    isValid, // no form errors and deep equality check on values
  } = props;

  const handleCancelForm = () => {
    resetForm();
    onCancelEdit();
  };

  // if form is adding a new record, run validation
  // if form has existing values, only submit if values have changed
  const handleSubmitForm = () => {
    if (isNewRecord || (!isNewRecord && isValid)) {
      handleSubmit();
    } else {
      handleCancelForm();
    }
  };

  return (
    <STableRow withHighlight={isEditing} withPointer={isEditable}>
      {columns.map(column => {
        return (
          <STableCell
            key={`${row.id}-${column.field}`}
            onClick={() => !column.disableEditOnClick && !isEditing && onEdit && onEdit()}
            width="auto"
          >
            <STableCellContent>
              {isEditing &&
                column.editCellRendererFramework &&
                column.editCellRendererFramework(row, !!isUpdating)}
              {!isEditing &&
                (column.cellRendererFramework
                  ? column.cellRendererFramework(row)
                  : row[column.field])}
            </STableCellContent>
          </STableCell>
        );
      })}
      <STableCell width="60px">{row.isOverride && <Tag>CMG</Tag>}</STableCell>
      {isEditable && (
        <STableCell width="70px">
          <SButtonWrapper>
            {isEditing && !isUpdating && (
              <React.Fragment>
                <StyledRecordSubmitIconButton
                  size="large"
                  icon={{ name: 'check', variant: 'solid' }}
                  onClick={() => handleSubmitForm()}
                />
                <StyledRecordCancelEditIconButton
                  size="large"
                  icon={{ name: 'times', variant: 'solid' }}
                  onClick={() => handleCancelForm()}
                />
              </React.Fragment>
            )}
          </SButtonWrapper>
        </STableCell>
      )}
    </STableRow>
  );
};

type FormikGenericFirmRecordRowProps<TValues = any> = OwnProps<TValues>;
type FormikGenericFirmRecordRowValues<TValues = any> = TValues;

const FormikGenericFirmRecordRow = withFormik<
  FormikGenericFirmRecordRowProps,
  FormikGenericFirmRecordRowValues
>({
  validateOnChange: false,
  validateOnBlur: true,
  isInitialValid: false,
  validate: async (values, { validationSchema }) => {
    try {
      await validateYupSchema(values, validationSchema, true);
    } catch (err) {
      return yupToFormErrors(err);
    }

    return {};
  },
  enableReinitialize: true,
  mapPropsToValues: ({ row, columns }) => {
    // Form field values are computed one of two ways.
    //
    // By default, we construct a data representation of the form and the
    // form field values by parsing the column config and locating matching keys
    // in the record.
    //
    // If the column config contains a function property called values, we utilize
    // that function to create the object reprepresentation of the form and
    // form field values - this mechanism is primarily used when a single column
    // contains multiple form fields.
    return columns.reduce(
      (acc, column) => ({
        ...acc,
        ...(column.values ? column.values(row) : { [column.field]: row[column.field] }),
      }),
      {}
    );
  },
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit(values);
  },
})(GenericFirmRecordRowComponent) as <TValues>(
  props: FormikGenericFirmRecordRowProps<TValues>
) => React.ReactElement<FormikGenericFirmRecordRowProps<TValues>>;

export default FormikGenericFirmRecordRow;
