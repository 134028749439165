import { AccountStatus } from '@capital-markets-gateway/api-client-identity';
import { CircularProgress, DialogContentText } from '@cmg/design-system';
import { FormikTextField } from '@cmg/design-system-formik';
import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect, VFC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import rolodexApiClient from '../../../../common/api/rolodexApiClient';
import {
  InfoItem,
  InfoLabel,
  InfoValue,
} from '../../../../common/components/layout/info-box/InfoBoxes';
import usePrevious from '../../../../common/util/usePrevious';
import { ServerErrorAlert } from '../../../../design-system/alert/ServerErrorAlert';
import { ConfirmationDialog } from '../../../../design-system/dialog/confirmation/ConfirmationDialog';
import { FormDialogContent } from '../../../../design-system/dialog/FormDialogContent';
import { FormDialogContentItem } from '../../../../design-system/dialog/FormDialogContentItem';
import {
  resetUpdateAccountStatus,
  selectAccountStatusError,
  selectAccountStatusSubmitting,
  updateAccountStatus,
} from '../../../account-detail/shared/ducks';
import { enableAccountFormSchema } from './ChangeAccountStatusDialog.model';
import { SContentContainer } from './ChangeAccountStatusDialog.styles';

const mapStateToProps = state => ({
  accountStatusSubmitting: selectAccountStatusSubmitting(state),
  accountStatusError: selectAccountStatusError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateAccountStatus,
      resetUpdateAccountStatus,
    },
    dispatch
  ),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;
export type Props = StateProps &
  DispatchProps & {
    accountId: string;
    accountStatus: AccountStatus;
    isOpen: boolean;
    onClose: () => void;
  };

export const ChangeAccountStatusDialogComponent: VFC<Props> = ({
  isOpen,
  onClose,
  accountId,
  accountStatus,
  accountStatusError,
  accountStatusSubmitting,
  actions: { updateAccountStatus, resetUpdateAccountStatus },
}) => {
  const { isEnableAccountFlow } = React.useMemo(
    () => ({
      isEnableAccountFlow: accountStatus !== AccountStatus.ACTIVE,
    }),
    [accountStatus]
  );
  const [cmgEntitySearch, setCmgEntitySearch] = React.useState({
    loading: false,
    cmgEntityName: '',
  });

  const previousAccountStatusSubmitting = usePrevious(accountStatusSubmitting);

  const enableAccountFormik = useFormik({
    initialValues: { cmgEntityKey: '' },
    onSubmit: ({ cmgEntityKey }) => {
      updateAccountStatus({
        status: AccountStatus.ACTIVE,
        accountId: accountId,
        cmgEntityKey: cmgEntityKey ?? '',
      });
    },
    validationSchema: enableAccountFormSchema,
    validateOnChange: false,
  });

  const handleClose = React.useCallback(() => {
    resetUpdateAccountStatus();
    enableAccountFormik.resetForm();
    onClose();
  }, [resetUpdateAccountStatus, enableAccountFormik, onClose]);

  useEffect(() => {
    if (!accountStatusSubmitting && previousAccountStatusSubmitting && !accountStatusError) {
      handleClose();
    }
  }, [accountStatusError, accountStatusSubmitting, handleClose, previousAccountStatusSubmitting]);

  const submitDisableAccount = useCallback(() => {
    updateAccountStatus({
      status: AccountStatus.DISABLED,
      accountId: accountId,
    });
  }, [accountId, updateAccountStatus]);

  const handleCmgEntityKey = React.useCallback(
    async (cmgEntityKey?: string | null) => {
      if (cmgEntityKey && cmgEntityKey.length === 9) {
        setCmgEntitySearch({ loading: true, cmgEntityName: '' });
        const response = await rolodexApiClient.getFirms({
          page: 1,
          key: cmgEntityKey,
        });
        if (response.ok) {
          const [firm] = response.data.data;
          setCmgEntitySearch({
            loading: false,
            cmgEntityName: firm.displayName ?? '',
          });
        } else {
          setCmgEntitySearch({
            loading: false,
            cmgEntityName: '',
          });
        }
      } else {
        setCmgEntitySearch({
          loading: false,
          cmgEntityName: '',
        });
      }
    },
    [setCmgEntitySearch]
  );

  React.useEffect(() => {
    void handleCmgEntityKey(enableAccountFormik.values.cmgEntityKey);
  }, [enableAccountFormik.values.cmgEntityKey, handleCmgEntityKey]);

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      isLoading={accountStatusSubmitting}
      title={!isEnableAccountFlow ? 'Disable Account' : 'Re-Assign Entity Key'}
      cancelButtonLabel="Cancel"
      onCancel={handleClose}
      submitButtonLabel={!isEnableAccountFlow ? 'Disable Account' : 'Confirm Changes'}
      submitButtonColor={!isEnableAccountFlow ? 'error' : 'primary'}
      onSubmit={() => {
        if (isEnableAccountFlow) {
          enableAccountFormik.handleSubmit();
        } else {
          submitDisableAccount();
        }
      }}
      content={
        <SContentContainer>
          {!isEnableAccountFlow && (
            <FormDialogContent
              error={
                accountStatusError && <ServerErrorAlert title="Error" error={accountStatusError} />
              }
              items={
                <FormDialogContentItem
                  value={
                    <DialogContentText>
                      Account will be disabled. Are you sure you want to proceed?
                    </DialogContentText>
                  }
                />
              }
            />
          )}
          {isEnableAccountFlow && (
            <FormikProvider value={enableAccountFormik}>
              <FormDialogContent
                error={
                  accountStatusError && (
                    <ServerErrorAlert title="Error" error={accountStatusError} />
                  )
                }
                items={
                  <FormDialogContentItem
                    value={
                      <React.Fragment>
                        <FormikTextField
                          placeholder="e.g. 000131787"
                          label="CMG Entity Key*"
                          name="cmgEntityKey"
                        />
                        {!['', null].includes(enableAccountFormik.values.cmgEntityKey) && (
                          <InfoItem>
                            <InfoLabel>Cmg Entity Name</InfoLabel>
                            {cmgEntitySearch.loading ? (
                              <CircularProgress size={14} />
                            ) : (
                              <InfoValue>{cmgEntitySearch.cmgEntityName || '-'}</InfoValue>
                            )}
                          </InfoItem>
                        )}
                      </React.Fragment>
                    }
                  />
                }
              />
            </FormikProvider>
          )}
        </SContentContainer>
      }
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAccountStatusDialogComponent);
