import type { ControlWithHelperTextProps, PercentageFieldProps } from '@cmg/design-system';
import { ControlWithHelperText, PercentageField } from '@cmg/design-system';
import { useField } from 'formik';
import React from 'react';

import { getDisplayValue, getFormValue } from './FormikPercentageField.model';

export type FormikPercentageFieldProps = Omit<
  PercentageFieldProps,
  'name' | 'value' | 'onChange' | 'onInputChange' | 'error' | 'helperText'
> &
  Pick<ControlWithHelperTextProps, 'showHelperTextInTooltip'> & {
    name: string;
    onChange?: (value: bigint | number | null) => void;
    precision?: number;
  };

export const FormikPercentageField: React.FC<FormikPercentageFieldProps> = ({
  precision = 2,
  name,
  showHelperTextInTooltip,
  onChange,
  ...props
}) => {
  const [field, meta, helpers] = useField<bigint | number | null>(name);
  const { onChange: _onChange, ...fieldProps } = field;
  const { value, error, touched } = meta;
  const { setValue } = helpers;

  const displayValue = React.useMemo(() => getDisplayValue(value, precision), [value, precision]);

  const handleChange = React.useCallback(
    (value: bigint | number | null) => {
      const formikValue = getFormValue(value, precision);

      setValue(formikValue);
      onChange?.(formikValue);
    },
    [setValue, onChange, precision]
  );

  return (
    <ControlWithHelperText
      tooltipVariant="error"
      showHelperTextInTooltip={showHelperTextInTooltip}
      helperText={touched && error}
      renderControl={renderControlProps => (
        <PercentageField
          {...props}
          {...fieldProps}
          name={name}
          value={displayValue}
          helperText={renderControlProps.helperText}
          onInputChange={handleChange}
          inputProps={{
            ...props.inputProps,
            ...renderControlProps.inputProps,
          }}
          error={touched && Boolean(meta.error)}
        />
      )}
    />
  );
};

export default FormikPercentageField;
