import { Icon, SecondaryButton } from '@cmg/common';
import { Form, FormikProps, withFormik } from 'formik';
import React from 'react';

import { GetCustomFirmMatchesFilters } from '../../../../../common/api/rolodexApiClient';
import {
  FirmMatchIdentifierType,
  firmMatchIdentifierTypeOptions,
} from '../../../../../types/domain/firm-match/FirmMatchIdentifierType';
import {
  SButtons,
  SClearIcon,
  SFormFields,
  SKeyPreview,
  StyledPrimaryButton,
  StyledSelectField,
  StyledTextInputField,
} from './CrmCustomFirmMatchForm.styles';

type CustomFirmMatchFormValues = {
  rolodexKey: string;
  name: string;
  address: string;
  webDomain: string;
  identifier: {
    type: FirmMatchIdentifierType | null;
    value: string;
  };
};

type OwnProps = {
  onSubmit?: (values: GetCustomFirmMatchesFilters) => void;
};

export type Props = OwnProps & FormikProps<CustomFirmMatchFormValues>;

/**
 * Displays Firm Details, and optionally allows editing
 * of those details.
 */
export const CrmCustomFirmMatchFormComponent: React.FC<Props> = ({
  values,
  handleSubmit,
  handleReset,
  setFieldValue,
}) => {
  return (
    <Form>
      <SFormFields>
        <StyledTextInputField
          name="rolodexKey"
          aria-label="CMG ID"
          label={
            <React.Fragment>
              CMG ID
              {values.rolodexKey.length > 0 && (
                <SKeyPreview>{values.rolodexKey.padStart(9, '0').slice(0, 9)}</SKeyPreview>
              )}
            </React.Fragment>
          }
          autoComplete="off"
          suffix={
            values.rolodexKey && (
              <SClearIcon onClick={() => setFieldValue('rolodexKey', '')}>
                <Icon name="times" />
              </SClearIcon>
            )
          }
        />
        <StyledTextInputField
          disabled={!!values.rolodexKey}
          name="name"
          placeholder=""
          aria-label="Name"
          label="Name"
        />
        <StyledSelectField
          options={firmMatchIdentifierTypeOptions}
          name="identifier.type"
          aria-label="Identifier"
          label="Identifier"
          placeholder="Type"
          disabled={!!values.rolodexKey}
        />
        <StyledTextInputField
          name="identifier.value"
          label=""
          placeholder="Identifier Value"
          disabled={!!values.rolodexKey}
        />
        <StyledTextInputField
          name="address"
          aria-label="Address"
          label="Address"
          disabled={!!values.rolodexKey}
        />
        <StyledTextInputField
          name="webDomain"
          aria-label="Web Domain"
          label="Web Domain"
          disabled={!!values.rolodexKey}
        />
      </SFormFields>
      <SButtons>
        <SecondaryButton onClick={handleReset}>Clear All</SecondaryButton>
        <StyledPrimaryButton onClick={() => handleSubmit()}>Search</StyledPrimaryButton>
      </SButtons>
    </Form>
  );
};

export default withFormik<OwnProps, CustomFirmMatchFormValues>({
  isInitialValid: false,
  validateOnChange: true,
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: () => ({
    rolodexKey: '',
    name: '',
    address: '',
    webDomain: '',
    identifier: {
      type: null,
      value: '',
    },
  }),
  handleSubmit: (values, formikBag) => {
    if (!formikBag.props.onSubmit) {
      return;
    }

    const {
      rolodexKey,
      name,
      address,
      webDomain,
      identifier: { type, value },
    } = values;

    // if searching by rolodexKey, only send up that search value
    if (rolodexKey) {
      return formikBag.props.onSubmit({
        rolodexKey: rolodexKey.padStart(9, '0').slice(0, 9),
      });
    }

    // other custom search values
    const customSearch: GetCustomFirmMatchesFilters = {
      ...(name ? { name } : {}),
      ...(address ? { address } : {}),
      ...(webDomain ? { webDomain } : {}),
      ...(type === FirmMatchIdentifierType.LEI ? { lei: value } : {}),
      ...(type === FirmMatchIdentifierType.CRD ? { crd: value } : {}),
      ...(type === FirmMatchIdentifierType.CIK ? { cik: value } : {}),
    };

    formikBag.props.onSubmit(customSearch);
  },
})(CrmCustomFirmMatchFormComponent);
