import { useFormikContext } from 'formik';
import React from 'react';

export const useIsFormAlertVisible = (when: boolean) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const { isSubmitting } = useFormikContext();

  React.useEffect(() => {
    if (isSubmitting && !isVisible && when) {
      setIsVisible(true);
    }
  }, [isSubmitting, isVisible, when]);

  React.useEffect(() => {
    if (!isSubmitting && isVisible && !when) {
      setIsVisible(false);
    }
  }, [isSubmitting, isVisible, when]);

  return isVisible;
};
