import type {
  AsyncAutocompleteProps,
  AutocompleteTextFieldProps,
  ControlWithHelperTextProps,
  UseAutocompleteProps,
} from '@cmg/design-system';
import { AsyncAutocomplete, ControlWithHelperText } from '@cmg/design-system';
import { useField } from 'formik';
import React from 'react';

export type FormikAsyncAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> = Omit<
  AsyncAutocompleteProps<T, Multiple, DisableClearable>,
  'TextFieldProps' | 'value' | 'onChange'
> &
  Pick<ControlWithHelperTextProps, 'showHelperTextInTooltip'> &
  Partial<Pick<AutocompleteTextFieldProps, 'label' | 'placeholder' | 'required'>> & {
    /**
     * Name attribute of the `input` element.
     *
     * This is required.
     */
    name: string;

    ref?: React.ForwardedRef<HTMLUListElement>;

    onChange?: (value: T | null) => void;

    TextFieldProps?: Pick<AutocompleteTextFieldProps, 'InputProps'>;
  };

type AsyncAutocompleteValue<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> = UseAutocompleteProps<T, Multiple, DisableClearable, true>['value'];

export function FormikAsyncAutocompleteField<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | false = false
>({
  name,
  label,
  placeholder,
  required,
  onChange,
  showHelperTextInTooltip,
  TextFieldProps,
  ...rest
}: FormikAsyncAutocompleteProps<T, Multiple, DisableClearable>): JSX.Element {
  const [field, meta, helpers] = useField<T | string | null | undefined>(name);
  const { multiple, ...fieldProps } = field;

  const handleOnChange = React.useCallback(
    (
      event: React.SyntheticEvent,
      value: AsyncAutocompleteValue<T, Multiple, DisableClearable>,
      reason: string
    ) => {
      onChange?.(value as T);
      if (reason === 'clear') {
        helpers.setValue(null, true);
        return;
      }
      helpers.setValue(value as T);
    },
    [helpers, onChange]
  );

  return (
    <ControlWithHelperText
      tooltipVariant="error"
      showHelperTextInTooltip={showHelperTextInTooltip}
      helperText={meta.touched && meta.error}
      renderControl={renderProps => (
        <AsyncAutocomplete<T, Multiple, DisableClearable>
          {...rest}
          {...fieldProps}
          TextFieldProps={{
            ...TextFieldProps,
            name,
            label,
            placeholder,
            required,
            error: meta.touched && Boolean(meta.error),
            helperText: renderProps.helperText,
          }}
          value={field.value as AsyncAutocompleteValue<T, Multiple, DisableClearable>}
          onChange={handleOnChange}
        />
      )}
    />
  );
}
