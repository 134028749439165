import { Banner, ServerErrors } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Prompt } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import LoadingOverlay from '../../../common/components/indicators/loading-overlay/LoadingOverlay';
import ChangeParentOrganizationModal from './components/change-parent-organization-modal/ChangeParentOrganizationModal';
import ConnectWithFactSetModal from './components/connect-with-factset-modal/ConnectWithFactSetModal';
import DisconnectWithFactSetModal from './components/disconnect-with-factset-modal/DisconnectWithFactSetModal';
import FirmDetailHeader from './components/firm-detail-header/FirmDetailHeader';
import FirmHistoryModal from './components/firm-history-modal/FirmHistoryModal';
import FirmVersionDiffModal from './components/firm-version-modal/FirmVersionDiffModal';
import FirmAddressesRecordPanel from './components/panels/FirmAddressesRecordPanel';
import FirmContactsRecordPanel from './components/panels/FirmContactsRecordPanel';
import FirmDetailsPanel from './components/panels/FirmDetailsPanel';
import FirmHierarchyPanel from './components/panels/FirmHierarchyPanel';
import FirmIdentifiersRecordPanel from './components/panels/FirmIdentifiersRecordPanel';
import { FirmLinkageSummaryPanel } from './components/panels/FirmLinkageSummaryPanel';
import FirmNamesRecordPanel from './components/panels/FirmNamesRecordPanel';
import FirmRolesRecordPanel from './components/panels/FirmRolesRecordPanel';
import FirmSectorsRecordPanel from './components/panels/FirmSectorsRecordPanel';
import {
  fetchFirm,
  resetState,
  selectFirm,
  selectFirmError,
  selectFirmLoading,
  selectIsEditingRecords,
} from './ducks';

const mapStateToProps = state => ({
  loading: selectFirmLoading(state),
  error: selectFirmError(state),
  firm: selectFirm(state),
  isEditingRecords: selectIsEditingRecords(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchFirm,
      resetState,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = RouteComponentProps<{ firmId: string }> & StateProps & DispatchProps;

export const RolodexFirmDetailRouteComponent: React.FC<Props> = ({
  loading,
  firm,
  error,
  isEditingRecords,
  actions,
  match,
}) => {
  const firmId = match.params.firmId;

  React.useEffect(() => {
    actions.fetchFirm({ id: firmId });

    return () => {
      actions.resetState();
    };
  }, [actions, firmId]);

  if (loading) {
    return <LoadingOverlay message="Loading organization..." />;
  }

  if (error) {
    return (
      <Banner variant="error" showIcon={false}>
        <ServerErrors error={error} />
      </Banner>
    );
  }

  return (
    <React.Fragment>
      {firm.id && (
        <React.Fragment>
          <Prompt
            when={isEditingRecords}
            message="You have unsaved changes that will be discarded if you navigate away. Are you sure you want to discard these changes?"
          />
          <FirmDetailHeader />
          <FirmHierarchyPanel firmId={firm.id} />
          <FirmDetailsPanel firmId={firm.id} />
          <FirmNamesRecordPanel firmId={firm.id} />
          <FirmIdentifiersRecordPanel firmId={firm.id} />
          <FirmSectorsRecordPanel firmId={firm.id} />
          <FirmRolesRecordPanel firmId={firm.id} />
          <FirmAddressesRecordPanel firmId={firm.id} />
          <FirmContactsRecordPanel firmId={firm.id} />
          <FirmLinkageSummaryPanel linkFrequencyData={firm.linkFrequencyData!} />
          <ConnectWithFactSetModal firmId={firm.id} />
          <DisconnectWithFactSetModal firmId={firm.id} />
          <FirmHistoryModal />
          <FirmVersionDiffModal />
          <ChangeParentOrganizationModal />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RolodexFirmDetailRouteComponent);
