// These must be the first lines in src/index.js
import 'react-app-polyfill/stable';

import { loggerUtil } from '@cmg/common';
import React from 'react';
import ReactDOM from 'react-dom';

import { getAppSettings } from './common/config/appSettings';
import App from './features/core/App';

const appSettings = getAppSettings();

loggerUtil.initDatadog({
  clusterEnvironment: appSettings.env.clusterEnvironment,
  datadogApplicationId: appSettings.logging.datadogApplicationId,
  datadogClientToken: appSettings.logging.datadogClientToken,
  serviceName: appSettings.logging.datadogServiceName,
  allowedTracingOrigin: appSettings.logging.datadogTracingOrigin,
  isDevelopment: appSettings.env.isDevelopment,
  version: appSettings.client.appVersion,
});

ReactDOM.render(<App />, document.getElementById('root'));
