import { InvestorCoverageReadPagedList } from '@capital-markets-gateway/api-client-rolodex';
import { duckPartFactory, reduxUtil } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import { AnyAction, combineReducers } from 'redux';
import { HIDE } from 'redux-modal/lib/actionTypes';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import * as accountApiClient from '../../../common/api/accountApiClient';
import rolodexApiClient, {
  FetchEmployeeInvestorCoverageResponse,
  GetEmployeeInvestorCoverageParams,
} from '../../../common/api/rolodexApiClient';
import systemManagementApiClient, {
  ChangeUsernameResponse,
  GetAccountsRolesResponse,
  GetAccountUserResponse,
  ResendInviteEmailResponse,
  ResetUserPasswordResponse,
  UnlockUserResponse,
  UpdateAccountUserResponse,
  UpdateAccountUserStatusResponse,
} from '../../../common/api/systemManagementApiClient';
import { RootState } from '../../../common/redux/rootReducer';
import { Role } from '../../../types/domain/role/role';
import { UserStatus } from '../../../types/domain/user/constants';
import {
  User,
  UserDetailsUpdate,
  UserRolesUpdate,
  UserUpdate,
} from '../../../types/domain/user/user';
import { selectSelfSubdomain } from '../../shared/ducks';
import { selectAccountSubdomain } from '../shared/ducks';

const SUCCESSFULLY_UPDATED = 'Successfully updated';

export const unlockUserDuckParts = duckPartFactory.makeAPIDuckParts<{
  userId: string;
}>({
  prefix: 'GLOBAL_MANAGEMENT/UNLOCK_USER',
});

export const fetchUserDuckParts = duckPartFactory.makeAPIDuckParts<{ userId: string }, User>({
  prefix: 'GLOBAL_MANAGEMENT/FETCH_USER',
});

export const resendInviteEmailDuckParts = duckPartFactory.makeAPIDuckParts<{
  userId: string;
}>({
  prefix: 'GLOBAL_MANAGEMENT/RESEND_INVITE_EMAIL',
});

export const resetUserPasswordDuckParts = duckPartFactory.makeAPIDuckParts<{
  userId: string;
}>({
  prefix: 'GLOBAL_MANAGEMENT/RESET_USER_PASSWORD',
});

export const updateUserDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    userId: string;
    user: UserUpdate;
  },
  User
>({
  prefix: 'GLOBAL_MANAGEMENT/UPDATE_USER',
});

export const updateUserDetailsDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    userId: string;
    userDetails: UserDetailsUpdate;
  },
  User
>({
  prefix: 'GLOBAL_MANAGEMENT/UPDATE_USER_DETAILS',
});

export const updateUserRolesDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    userId: string;
    userRoles: UserRolesUpdate;
  },
  User
>({
  prefix: 'GLOBAL_MANAGEMENT/UPDATE_USER_ROLES',
});

export const updateUserStatusDuckParts = duckPartFactory.makeAPIDuckParts<
  { userId: string; status: UserStatus },
  UserStatus
>({
  prefix: 'GLOBAL_MANAGEMENT/UPDATE_USER_STATUS',
});

export const fetchAccountRolesDuckParts = duckPartFactory.makeAPIDuckParts<undefined, Role[]>({
  prefix: 'GLOBAL_MANAGEMENT/FETCH_ACCOUNT_ROLES',
});

export const changeUsernameDuckParts = duckPartFactory.makeAPIDuckParts<
  {
    userId: string;
    email: string;
  },
  { email: string }
>({
  prefix: 'GLOBAL_MANAGEMENT/CHANGE_USERNAME',
});

export const fetchEmployeeInvestorCoverageDuckParts = duckPartFactory.makeAPIDuckParts<
  GetEmployeeInvestorCoverageParams & { hasPermission: boolean },
  InvestorCoverageReadPagedList | null
>({
  prefix: 'GLOBAL_MANAGEMENT/FETCH_EMPLOYEE_INVESTOR_COVERAGE',
});

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  RESET_USER_DETAIL_STATE = 'GLOBAL_MANAGEMENT/RESET_USER_DETAIL_STATE',
}

/**
 * ACTION CREATORS
 */
export const resetUserDetailState = () => ({
  type: ActionTypes.RESET_USER_DETAIL_STATE,
});

export const unlockUser = unlockUserDuckParts.actionCreators.request;
type UnlockUserAction = ReturnType<typeof unlockUser>;
export const fetchUser = fetchUserDuckParts.actionCreators.request;
type FetchUserAction = ReturnType<typeof fetchUser>;
export const resendInviteEmail = resendInviteEmailDuckParts.actionCreators.request;
type ResendInviteEmailAction = ReturnType<typeof resendInviteEmail>;
export const resetUserPassword = resetUserPasswordDuckParts.actionCreators.request;
type ResetUserPasswordAction = ReturnType<typeof resetUserPassword>;
export const updateUser = updateUserDuckParts.actionCreators.request;
type UpdateUserAction = ReturnType<typeof updateUser>;
export const updateUserDetails = updateUserDetailsDuckParts.actionCreators.request;
type UpdateUserDetailsAction = ReturnType<typeof updateUserDetails>;
export const updateUserRoles = updateUserRolesDuckParts.actionCreators.request;
type UpdateUserRolesAction = ReturnType<typeof updateUserRoles>;
export const updateUserStatus = updateUserStatusDuckParts.actionCreators.request;
type UpdateUserStatusAction = ReturnType<typeof updateUserStatus>;
export const fetchAccountRoles = fetchAccountRolesDuckParts.actionCreators.request;
type FetchAccountRolesAction = ReturnType<typeof fetchAccountRoles>;
export const changeUsername = changeUsernameDuckParts.actionCreators.request;
type ChangeUsernameAction = ReturnType<typeof changeUsername>;
export const fetchEmployeeInvestorCoverage =
  fetchEmployeeInvestorCoverageDuckParts.actionCreators.request;
type FetchEmployeeInvestorCoverageAction = ReturnType<typeof fetchEmployeeInvestorCoverage>;

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.RESET_USER_DETAIL_STATE]: ReturnType<typeof resetUserDetailState>;
};

/**
 * REDUCERS
 */
const { createReducer } = reduxUtil;

export const initialState = {
  user: fetchUserDuckParts.initialState,
  updateUser: updateUserDuckParts.initialState,
  updateUserDetails: updateUserDetailsDuckParts.initialState,
  updateUserRoles: updateUserRolesDuckParts.initialState,
  updateUserStatus: updateUserStatusDuckParts.initialState,
  resendInvite: resendInviteEmailDuckParts.initialState,
  resetPassword: resetUserPasswordDuckParts.initialState,
  unlockUser: unlockUserDuckParts.initialState,
  accountRoles: fetchAccountRolesDuckParts.initialState,
  changeUsername: changeUsernameDuckParts.initialState,
  employeeInvestorCoverage: fetchEmployeeInvestorCoverageDuckParts.initialState,
};

export type ReducerState = typeof initialState;

const customUserReducer = createReducer<ReducerState['user'], any>(initialState.user, {
  // User modified by the update api call. That response is now our source of truth.
  [updateUserDuckParts.actionTypes.SUCCESS]: (
    curState,
    { payload }: ReturnType<typeof updateUserDuckParts.actionCreators.success>
  ) => ({
    ...curState,
    data: payload,
  }),
  [updateUserDetailsDuckParts.actionTypes.SUCCESS]: (
    curState,
    { payload }: ReturnType<typeof updateUserDetailsDuckParts.actionCreators.success>
  ) => ({
    ...curState,
    data: payload,
  }),
  [updateUserRolesDuckParts.actionTypes.SUCCESS]: (
    curState,
    { payload }: ReturnType<typeof updateUserRolesDuckParts.actionCreators.success>
  ) => ({
    ...curState,
    data: payload,
  }),
  // User status updated, update the user.
  [updateUserStatusDuckParts.actionTypes.SUCCESS]: (
    curState,
    { payload }: ReturnType<typeof updateUserStatusDuckParts.actionCreators.success>
  ) => ({
    ...curState,
    data: curState.data
      ? {
          ...curState.data,
          status: payload,
        }
      : null,
  }),
  // User unlocked, update the user.
  [unlockUserDuckParts.actionTypes.SUCCESS]: curState => ({
    ...curState,
    data: curState.data
      ? {
          ...curState.data,
          isLockedOut: false,
          lockoutEnd: null,
        }
      : null,
  }),
  // User email changed, update the user.
  [changeUsernameDuckParts.actionTypes.SUCCESS]: (
    curState,
    { payload }: ReturnType<typeof changeUsernameDuckParts.actionCreators.success>
  ) => ({
    ...curState,
    data: curState.data
      ? {
          ...curState.data,
          email: payload.email,
        }
      : null,
  }),
});

const userReducers = (
  userState: ReducerState['user'] | undefined,
  action
): ReducerState['user'] => {
  const state1 = fetchUserDuckParts.reducer(userState, action);
  return customUserReducer(state1, action);
};

const customChangeUsernameReducer = createReducer<ReducerState['changeUsername'], any>(
  initialState.changeUsername,
  {
    // @ts-ignore
    [HIDE]: curState => ({
      ...curState,
      error: null,
    }),
  }
);

// to reset Banner in case of an error
const changeUsernameReducers = (
  state: ReducerState['changeUsername'] | undefined,
  action
): ReducerState['changeUsername'] => {
  const state1 = changeUsernameDuckParts.reducer(state, action);
  return customChangeUsernameReducer(state1, action);
};

// This reducer acts on the entire state of this duck. Has access to duck state and must return duck state.
const crossSliceReducer = createReducer<ReducerState, Actions>(initialState, {
  [ActionTypes.RESET_USER_DETAIL_STATE]: () => ({ ...initialState }),
});

const combinedReducers = combineReducers<ReducerState>({
  user: userReducers,
  updateUser: updateUserDuckParts.reducer,
  updateUserDetails: updateUserDetailsDuckParts.reducer,
  updateUserRoles: updateUserRolesDuckParts.reducer,
  updateUserStatus: updateUserStatusDuckParts.reducer,
  resendInvite: resendInviteEmailDuckParts.reducer,
  resetPassword: resetUserPasswordDuckParts.reducer,
  unlockUser: unlockUserDuckParts.reducer,
  accountRoles: fetchAccountRolesDuckParts.reducer,
  changeUsername: changeUsernameReducers,
  employeeInvestorCoverage: fetchEmployeeInvestorCoverageDuckParts.reducer,
});

// Combines our individual slice reducers and the cross slice reducer.
export default function duckReducer(state: ReducerState = initialState, action: AnyAction) {
  const intermediateState = combinedReducers(state, action);
  return crossSliceReducer(intermediateState, action);
}

/**
 * SELECTORS
 */
const selectState = (state: RootState): ReducerState => state.adminAccountUserDetail;
const userSelectors = fetchUserDuckParts.makeSelectors(state => selectState(state).user);
const updateUserSelectors = fetchUserDuckParts.makeSelectors(
  state => selectState(state).updateUser
);
const updateUserDetailsSelectors = fetchUserDuckParts.makeSelectors(
  state => selectState(state).updateUserDetails
);
const updateUserRolesSelectors = fetchUserDuckParts.makeSelectors(
  state => selectState(state).updateUserRoles
);
const updateUserStatusSelectors = updateUserStatusDuckParts.makeSelectors(
  state => selectState(state).updateUserStatus
);
const resendInviteSelectors = resendInviteEmailDuckParts.makeSelectors(
  state => selectState(state).resendInvite
);
const resetPasswordSelectors = resetUserPasswordDuckParts.makeSelectors(
  state => selectState(state).resetPassword
);
const changeUsernameSelectors = changeUsernameDuckParts.makeSelectors(
  state => selectState(state).changeUsername
);
const unlockUserSelectors = unlockUserDuckParts.makeSelectors(
  state => selectState(state).unlockUser
);
const accountRolesSelectors = fetchAccountRolesDuckParts.makeSelectors(
  state => selectState(state).accountRoles
);
const employeeInvestorCoverageSelectors = fetchEmployeeInvestorCoverageDuckParts.makeSelectors(
  state => selectState(state).employeeInvestorCoverage
);

export const selectUserError = userSelectors.selectError;
export const selectUser = userSelectors.selectData;
export const selectUserLoading = userSelectors.selectLoading;
export const selectResendInviteEmailSuccess = resendInviteSelectors.selectSucceeded;
export const selectResendInviteEmailError = resendInviteSelectors.selectError;
export const selectResendInviteEmailLoading = resendInviteSelectors.selectLoading;
export const selectResetPasswordEmailSent = resetPasswordSelectors.selectSucceeded;
export const selectResetPasswordError = resetPasswordSelectors.selectError;
export const selectResetPasswordLoading = resetPasswordSelectors.selectLoading;
export const selectChangeUsernameError = changeUsernameSelectors.selectError;
export const selectChangeUsernameLoading = changeUsernameSelectors.selectLoading;
export const selectUpdateUserDetailsLoading = updateUserDetailsSelectors.selectLoading;
export const selectUpdateUserDetailsError = updateUserDetailsSelectors.selectError;
export const selectUpdateUserRolesLoading = updateUserRolesSelectors.selectLoading;
export const selectUpdateUserRolesError = updateUserRolesSelectors.selectError;
export const selectUpdateUserStatusLoading = updateUserStatusSelectors.selectLoading;
export const selectUpdateUserStatusError = updateUserStatusSelectors.selectError;
export const selectUserUpdating = createSelector(
  [updateUserSelectors.selectLoading, updateUserStatusSelectors.selectLoading],
  (updateUserLoading, updateUserStatusLoading) => {
    return updateUserLoading || updateUserStatusLoading;
  }
);
export const selectUserUpdatingError = createSelector(
  [updateUserSelectors.selectError, updateUserStatusSelectors.selectError],
  (updateUserError, updateUserStatusError) => {
    return updateUserError || updateUserStatusError;
  }
);
export const selectRolesError = accountRolesSelectors.selectError;
export const selectRoles = createSelector([accountRolesSelectors.selectData], roles => {
  return roles || [];
});
export const selectRolesLoading = accountRolesSelectors.selectLoading;
export const selectUnlockUserLoading = unlockUserSelectors.selectLoading;
export const selectUnlockUserError = unlockUserSelectors.selectError;
export const selectEmployeeInvestorCoverage = employeeInvestorCoverageSelectors.selectData;
export const selectEmployeeInvestorCoverageLoading =
  employeeInvestorCoverageSelectors.selectLoading;
export const selectEmployeeInvestorCoverageError = employeeInvestorCoverageSelectors.selectError;

/**
 * SAGAS
 */
export function* fetchUserSaga({ payload }: FetchUserAction): SagaIterator {
  const { userId } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: GetAccountUserResponse | accountApiClient.GetMyAccountUserResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.getMyAccountUser, userId);
  } else {
    response = yield call(systemManagementApiClient.admin.getAccountUser, accountSubdomain, userId);
  }

  if (response.ok) {
    const user = response.data;

    yield put(fetchUserDuckParts.actionCreators.success(user));
  } else {
    yield put(fetchUserDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* resendInviteEmailSaga({ payload }: ResendInviteEmailAction): SagaIterator {
  const { userId } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: ResendInviteEmailResponse | accountApiClient.ResendMyAccountUserInviteEmailResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.resendMyAccountUserInviteEmail, userId);
  } else {
    response = yield call(
      systemManagementApiClient.admin.resendInviteEmail,
      accountSubdomain,
      userId
    );
  }

  if (response.ok) {
    yield put(resendInviteEmailDuckParts.actionCreators.success(undefined));
    SnackbarManager.success('Successfully sent invite email');
  } else {
    yield put(resendInviteEmailDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* resetPasswordSaga({ payload }: ResetUserPasswordAction): SagaIterator {
  const { userId } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: ResetUserPasswordResponse | accountApiClient.ResetMyAccountUserPasswordResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.resetMyAccountUserPasswordRequest, userId);
  } else {
    response = yield call(
      systemManagementApiClient.admin.resetUserPasswordRequest,
      accountSubdomain,
      userId
    );
  }
  if (response.ok) {
    yield put(resetUserPasswordDuckParts.actionCreators.success(undefined));
    SnackbarManager.success('Successfully reset user password');
  } else {
    yield put(resetUserPasswordDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* changeUsernameSaga({ payload }: ChangeUsernameAction): SagaIterator {
  const { userId, email } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: ChangeUsernameResponse | accountApiClient.ChangeMyAccountUserEmailResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.changeMyAccountUserEmailRequest, userId, email);
  } else {
    response = yield call(
      systemManagementApiClient.admin.changeUsernameRequest,
      accountSubdomain,
      userId,
      email
    );
  }
  if (response.ok) {
    // todo change user email in redux state
    yield put(changeUsernameDuckParts.actionCreators.success({ email }));
    SnackbarManager.success('Successfully changed user email');
  } else {
    yield put(changeUsernameDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* fetchAccountRolesSaga(action: FetchAccountRolesAction): SagaIterator {
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  const apiMethod =
    accountSubdomain === selfSubdomain
      ? accountApiClient.getMyAccountRoles
      : systemManagementApiClient.admin.getAccountRoles;
  const response: accountApiClient.GetMyAccountRolesResponse | GetAccountsRolesResponse =
    yield call(apiMethod, accountSubdomain, {
      page: 1,
    });

  if (response.ok) {
    yield put(fetchAccountRolesDuckParts.actionCreators.success(response.data.data));
  } else {
    yield put(fetchAccountRolesDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* updateUserSaga({ payload }: UpdateUserAction) {
  const { userId, user } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: UpdateAccountUserResponse | accountApiClient.UpdateMyAccountUserResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.updateMyAccountUser, userId, user);
  } else {
    response = yield call(
      systemManagementApiClient.admin.updateAccountUser,
      accountSubdomain,
      userId,
      user
    );
  }

  if (response.ok) {
    yield put(updateUserDuckParts.actionCreators.success(response.data));
    SnackbarManager.success(SUCCESSFULLY_UPDATED);
  } else {
    yield put(updateUserDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* updateUserDetailsSaga({ payload }: UpdateUserDetailsAction): SagaIterator {
  const { userId, userDetails } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);
  const user = yield select(selectUser);

  const updatedUser: UserUpdate = { ...userDetails, roles: user.roles.map(role => role.id) };

  let response: UpdateAccountUserResponse | accountApiClient.UpdateMyAccountUserResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.updateMyAccountUser, userId, updatedUser);
  } else {
    response = yield call(
      systemManagementApiClient.admin.updateAccountUser,
      accountSubdomain,
      userId,
      updatedUser
    );
  }

  if (response.ok) {
    yield put(updateUserDetailsDuckParts.actionCreators.success(response.data));
    SnackbarManager.success(SUCCESSFULLY_UPDATED);
  } else {
    yield put(updateUserDetailsDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* updateUserRolesSaga({ payload }: UpdateUserRolesAction) {
  const { userId, userRoles } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);
  const user = yield select(selectUser);

  const updatedUser: UserUpdate = {
    ...userRoles,
    firstName: user.firstName,
    lastName: user.lastName,
    employeeKey: user.employeeKey,
    jobFunction: user.jobFunction,
  };

  let response: UpdateAccountUserResponse | accountApiClient.UpdateMyAccountUserResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.updateMyAccountUser, userId, updatedUser);
  } else {
    response = yield call(
      systemManagementApiClient.admin.updateAccountUser,
      accountSubdomain,
      userId,
      updatedUser
    );
  }

  if (response.ok) {
    yield put(updateUserRolesDuckParts.actionCreators.success(response.data));
    SnackbarManager.success(SUCCESSFULLY_UPDATED);
  } else {
    yield put(updateUserRolesDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* updateUserStatusSaga({ payload }: UpdateUserStatusAction): SagaIterator {
  const { userId, status } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response:
    | UpdateAccountUserStatusResponse
    | accountApiClient.UpdateMyAccountUserStatusResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.updateMyAccountUserStatus, userId, status);
  } else {
    response = yield call(
      systemManagementApiClient.admin.updateAccountUserStatus,
      accountSubdomain,
      userId,
      status
    );
  }

  if (response.ok) {
    yield put(updateUserStatusDuckParts.actionCreators.success(status));
    const actionLabel = UserStatus.ACTIVE === status ? 'enabled' : 'disabled';
    SnackbarManager.success(`Successfully ${actionLabel} user status`);
  } else {
    yield put(updateUserStatusDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* unlockUserSaga({ payload }: UnlockUserAction): SagaIterator {
  const { userId } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: UnlockUserResponse | accountApiClient.UnlockMyAccountUserResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.unlockMyAccountUser, userId);
  } else {
    response = yield call(systemManagementApiClient.admin.unlockUser, accountSubdomain, userId);
  }
  if (response.ok) {
    yield put(unlockUserDuckParts.actionCreators.success(undefined));
    SnackbarManager.success('User has been unlocked');
  } else {
    yield put(unlockUserDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* fetchEmployeeInvestorCoverageSaga({
  payload,
}: FetchEmployeeInvestorCoverageAction): SagaIterator {
  if (!payload.hasPermission) {
    yield put(fetchEmployeeInvestorCoverageDuckParts.actionCreators.success(null));
    return;
  }

  const response: FetchEmployeeInvestorCoverageResponse = yield call(
    rolodexApiClient.fetchEmployeeInvestorCoverage,
    payload
  );
  if (response.ok) {
    yield put(fetchEmployeeInvestorCoverageDuckParts.actionCreators.success(response.data));
  } else {
    yield put(fetchEmployeeInvestorCoverageDuckParts.actionCreators.failure(response.data.error));
  }
}

export function* adminAccountUserDetailSaga() {
  yield takeLatest<FetchUserAction>(fetchUserDuckParts.actionTypes.REQUEST, fetchUserSaga);
  yield takeLatest<ResendInviteEmailAction>(
    resendInviteEmailDuckParts.actionTypes.REQUEST,
    resendInviteEmailSaga
  );
  yield takeLatest<ResetUserPasswordAction>(
    resetUserPasswordDuckParts.actionTypes.REQUEST,
    resetPasswordSaga
  );
  yield takeLatest<ChangeUsernameAction>(
    changeUsernameDuckParts.actionTypes.REQUEST,
    changeUsernameSaga
  );
  yield takeLatest<UpdateUserAction>(updateUserDuckParts.actionTypes.REQUEST, updateUserSaga);
  yield takeLatest<UpdateUserDetailsAction>(
    updateUserDetailsDuckParts.actionTypes.REQUEST,
    updateUserDetailsSaga
  );
  yield takeLatest<UpdateUserRolesAction>(
    updateUserRolesDuckParts.actionTypes.REQUEST,
    updateUserRolesSaga
  );
  yield takeLatest<UpdateUserStatusAction>(
    updateUserStatusDuckParts.actionTypes.REQUEST,
    updateUserStatusSaga
  );
  yield takeLatest<FetchAccountRolesAction>(
    fetchAccountRolesDuckParts.actionTypes.REQUEST,
    fetchAccountRolesSaga
  );
  yield takeLatest<UnlockUserAction>(unlockUserDuckParts.actionTypes.REQUEST, unlockUserSaga);
  yield takeLatest<FetchEmployeeInvestorCoverageAction>(
    fetchEmployeeInvestorCoverageDuckParts.actionTypes.REQUEST,
    fetchEmployeeInvestorCoverageSaga
  );
}
