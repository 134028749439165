import {
  OIDCLogin,
  OIDCLoginCallback,
  OIDCLogout,
  OIDCLogoutCallback,
  permissionsByEntity,
  PrivateRouteContent,
} from '@cmg/auth';
import { apiTypes, ApplicationError, urlUtil } from '@cmg/common';
import React from 'react';
import { StaticContext } from 'react-router';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { getAppSettings } from '../../common/config/appSettings';
import routeFactory from '../../common/util/routeFactory';
import DesignSystemAccountDetailRoute from '../design-system/account-detail/AccountDetailRoute';
import AccountListRoute from '../global-management/account-list/AccountListRoute';
import DraftAccountListRoute from '../global-management/draft-account-list/DraftAccountListRoute';
import TraitDetailRoute from '../global-management/trait-detail/TraitDetailRoute';
import TraitListRoute from '../global-management/trait-list/TraitListRoute';
import DesignSystemProfileRoute from '../identity-management/design-system/profile/ProfileRoute';
import { ErrorCode } from '../identity-management/error/constants';
import ErrorRoute from '../identity-management/error/ErrorRoute';
import FirstTimeActivationRoute from '../identity-management/first-time-activation/FirstTimeActivationRoute';
import ForgotPasswordRoute from '../identity-management/forgot-password/ForgotPasswordRoute';
import LoggedOutRoute from '../identity-management/loggedOut/LoggedOutRoute';
import LoginRoute from '../identity-management/login/LoginRoute';
import ResetPasswordRoute from '../identity-management/reset-password/ResetPasswordRoute';
import SelectDomainRoute from '../identity-management/select-domain/SelectDomainRoute';
import UnlockedRoute from '../identity-management/unlocked/UnlockedRoute';
import InvestorSignupRoute from '../investor-signup/InvestorSignupRoute';
import SuccessScreenRoute from '../investor-signup/success-screen/SuccessScreenRoute';
import PrivacyPolicyDoc from '../privacy-policy/PrivacyPolicyDoc';
import CRMManagementRoute from '../rolodex/crm-management/CRMManagementRoute';
import RolodexRoute from '../rolodex/RolodexRoute';

type NoAccessProps = {
  isLoggedIn: boolean;
} & RouteComponentProps;

/**
 * NoAccessRedirect fallback redirect wrapper
 *
 * When the user is not logged in - redirect the user back
 * to the oidc login route url and set the location state to the current location.
 * When the user is logged in - redirect to the error route with forbidden error code
 */
export const NoAccessRedirect: React.FC<NoAccessProps> = ({ location, isLoggedIn }) => {
  // User is not logged in - redirect to login
  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: routeFactory.oidcLogin.getUrlPath(),
          state: routeFactory.oidcLogin.getRouteState({
            location: location,
          }),
        }}
      />
    );
  }

  // User is logged in but does not have permissions to view this route - redirect to forbidden
  return (
    <Redirect
      to={routeFactory.error.getUrlPath({
        errorCode: ErrorCode.FORBIDDEN,
      })}
    />
  );
};

/**
 * Responsible for application level routes. Most routes in our sitemap should be represented here.
 * Occasionally we will use the ReactRouter v4 feature of nesting <Routes> further down the component tree.
 */
export const RootRouter: React.FC = () => {
  const appSettings = getAppSettings();

  return (
    <Switch>
      {/* Identity */}
      <Route exact path={routeFactory.error.routePath} component={ErrorRoute} />
      <Route exact path={routeFactory.selectDomain.routePath} component={SelectDomainRoute} />
      <Route exact path={routeFactory.unlocked.routePath} component={UnlockedRoute} />
      <Route
        exact
        path={routeFactory.firstTimeActivation.routePath}
        component={FirstTimeActivationRoute}
      />
      <Route exact path={routeFactory.login.routePath} component={LoginRoute} />
      <Route exact path={routeFactory.forgotPassword.routePath} component={ForgotPasswordRoute} />
      <Route exact path={routeFactory.resetPassword.routePath} component={ResetPasswordRoute} />
      <Route
        path={routeFactory.profile.routePath}
        render={routeProps => (
          <PrivateRouteContent
            renderNoAccess={isLoggedIn => (
              <NoAccessRedirect isLoggedIn={isLoggedIn} {...routeProps} />
            )}
          >
            <DesignSystemProfileRoute />
          </PrivateRouteContent>
        )}
      />
      {/* Identity - Investor Signup */}
      {urlUtil.getAccountSubdomain(window.location) === 'signup' ? (
        <Route exact path={routeFactory.investorSignup.routePath} component={InvestorSignupRoute} />
      ) : (
        <Route
          path={routeFactory.investorSignup.routePath}
          exact
          render={() => (
            <ApplicationError
              errorCode={apiTypes.ServiceErrorCode.NOT_FOUND}
              returnUrl={routeFactory.root.routePath}
            />
          )}
        />
      )}
      {urlUtil.getAccountSubdomain(window.location) === 'signup' ? (
        <Route
          exact
          path={routeFactory.investorSignupSuccess.routePath}
          component={SuccessScreenRoute}
        />
      ) : (
        <Route
          path={routeFactory.investorSignupSuccess.routePath}
          exact
          render={() => (
            <ApplicationError
              errorCode={apiTypes.ServiceErrorCode.NOT_FOUND}
              returnUrl={routeFactory.root.routePath}
            />
          )}
        />
      )}
      {/* OIDC */}
      <Route exact path={routeFactory.loggedOut.routePath} component={LoggedOutRoute} />
      <Route
        exact
        path={routeFactory.oidcLogin.routePath}
        render={(routeProps: RouteComponentProps<any, StaticContext, any>) => {
          /**
           * if location is set, then it is set to { from: pathname }
           */
          const returnUrl = routeProps.location.state?.from?.pathname || '/';

          return (
            <OIDCLogin
              onError={err => {
                window.location.href =
                  appSettings.client.basename + routeFactory.error.getUrlPath({});
              }}
              returnUrl={returnUrl}
            />
          );
        }}
      />
      <Route exact path={routeFactory.oidcLogout.routePath} component={OIDCLogout} />
      <Route
        exact
        path={routeFactory.oidcLoginCallback.routePath}
        render={routeProps => (
          <OIDCLoginCallback
            onSuccess={returnUrl => {
              routeProps.history.push(returnUrl || routeFactory.profile.getUrlPath());
            }}
          />
        )}
      />
      <Route
        exact
        path={routeFactory.oidcLogoutCallback.routePath}
        render={routeProps => (
          <OIDCLogoutCallback
            onSuccess={() => routeProps.history.push(routeFactory.loggedOut.getUrlPath())}
          />
        )}
      />
      {/* Global Management */}
      <Route
        path={routeFactory.adminAccounts.routePath}
        render={routeProps => (
          <PrivateRouteContent
            requiredPermissions={[permissionsByEntity.GlobalAccount.READ]}
            renderNoAccess={isLoggedIn => (
              <NoAccessRedirect isLoggedIn={isLoggedIn} {...routeProps} />
            )}
          >
            <AccountListRoute {...routeProps} />
          </PrivateRouteContent>
        )}
      />
      <Route
        path={routeFactory.adminDraftAccounts.routePath}
        render={routeProps => (
          <PrivateRouteContent
            requiredPermissions={[permissionsByEntity.GlobalAccount.READ]}
            renderNoAccess={isLoggedIn => (
              <NoAccessRedirect isLoggedIn={isLoggedIn} {...routeProps} />
            )}
          >
            <DraftAccountListRoute {...routeProps} />
          </PrivateRouteContent>
        )}
      />
      <Route
        path={
          // FIXME: if the current subdomain isn't `system`, the required param `accountSubdomain` is missing
          routeFactory.accountDetail.routePath as ':accountSubdomain'
        }
        render={routeProps => (
          <PrivateRouteContent
            requiredPermissions={[
              permissionsByEntity.GlobalAccount.READ,
              permissionsByEntity.Account.READ,
            ]}
            requireAllPermissions={false}
            renderNoAccess={isLoggedIn => (
              <NoAccessRedirect isLoggedIn={isLoggedIn} {...routeProps} />
            )}
          >
            <DesignSystemAccountDetailRoute {...routeProps} />
          </PrivateRouteContent>
        )}
      />
      <Route
        path={routeFactory.adminTraits.routePath}
        exact
        render={routeProps => (
          <PrivateRouteContent
            requiredPermissions={[permissionsByEntity.GlobalAccount.READ]}
            renderNoAccess={isLoggedIn => (
              <NoAccessRedirect isLoggedIn={isLoggedIn} {...routeProps} />
            )}
          >
            <TraitListRoute {...routeProps} />
          </PrivateRouteContent>
        )}
      />
      <Route
        path={routeFactory.adminTraitDetail.routePath}
        exact
        render={routeProps => (
          <PrivateRouteContent
            requiredPermissions={[permissionsByEntity.GlobalAccount.READ]}
            renderNoAccess={isLoggedIn => (
              <NoAccessRedirect isLoggedIn={isLoggedIn} {...routeProps} />
            )}
          >
            <TraitDetailRoute {...routeProps} />
          </PrivateRouteContent>
        )}
      />

      {/* Rolodex */}
      <Route
        path={routeFactory.rolodex.routePath}
        render={routeProps => (
          <PrivateRouteContent
            requiredPermissions={[permissionsByEntity.Firm.READ]}
            renderNoAccess={isLoggedIn => (
              <NoAccessRedirect isLoggedIn={isLoggedIn} {...routeProps} />
            )}
          >
            <RolodexRoute />
          </PrivateRouteContent>
        )}
      />
      {/* Customer Facing CRM Management */}
      <Route
        path={routeFactory.customerCrmManagement.routePath}
        render={routeProps => (
          <PrivateRouteContent
            requiredPermissions={[permissionsByEntity.CRM.FULL]}
            renderNoAccess={isLoggedIn => (
              <NoAccessRedirect isLoggedIn={isLoggedIn} {...routeProps} />
            )}
          >
            <CRMManagementRoute {...routeProps} />
          </PrivateRouteContent>
        )}
      />

      {/* Privacy Policy */}
      <Route
        exact
        path={routeFactory.privacyPolicy.routePath}
        render={routeProps => (
          <PrivateRouteContent
            renderNoAccess={isLoggedIn => (
              <NoAccessRedirect isLoggedIn={isLoggedIn} {...routeProps} />
            )}
          >
            <PrivacyPolicyDoc />
          </PrivateRouteContent>
        )}
      />

      {/* Fallback */}
      <Route render={() => <Redirect to={routeFactory.profile.getUrlPath()} />} />
    </Switch>
  );
};

export default RootRouter;
