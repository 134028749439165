export const getDisplayValue = (
  percentageValue: bigint | number | null,
  precision: number
): bigint | number | null => {
  if (percentageValue === null) {
    return null;
  }

  if (typeof percentageValue === 'number') {
    return percentageValue * 10 ** precision;
  }

  return BigInt(percentageValue) * BigInt(10 ** precision);
};

export const getFormValue = (
  displayValue: bigint | number | null,
  precision: number
): bigint | number | null => {
  if (displayValue === null) {
    return null;
  }

  if (typeof displayValue === 'number') {
    return displayValue / 10 ** precision;
  }

  return BigInt(displayValue) / BigInt(10 ** precision);
};
