import type { ControlWithHelperTextProps, TextFieldProps } from '@cmg/design-system';
import { ControlWithHelperText, MenuItem, TextField } from '@cmg/design-system';
import { useField } from 'formik';
import type { ChangeEvent, ReactNode } from 'react';
import React from 'react';

export type SelectOption<TValue> = Readonly<{
  value: TValue;
  label: string;
  [key: string]: unknown;
}>;

export type FormikSelectFieldRenderOptionProps<TValue> = {
  key: string | number;
  option: SelectOption<TValue>;
};

export type FormikSelectFieldProps<TValue> = Omit<
  TextFieldProps,
  'name' | 'error' | 'onChange' | 'onBlur' | 'value'
> &
  Pick<ControlWithHelperTextProps, 'showHelperTextInTooltip'> &
  Readonly<{
    name: string;
    options: SelectOption<TValue>[];
    renderOption?: (renderOptionProps: FormikSelectFieldRenderOptionProps<TValue>) => ReactNode;
    renderValue?: (value: TValue) => ReactNode;
    onChange?: (value: TValue) => void;
  }>;

export function FormikSelectField<TValue>({
  name,
  options,
  renderOption,
  onChange,
  showHelperTextInTooltip,
  ...restProps
}: FormikSelectFieldProps<TValue>): JSX.Element {
  const [tooltipOpen, setTooltipOpen] = React.useState(false); // explicit control of tooltip open state to fix ECM-41558
  const [field, meta, helpers] = useField<TValue | null>(name);
  const { value, error, touched } = meta;
  const { setValue } = helpers;

  const handleChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value as TValue;
      setValue(value);
      onChange?.(value);
    },
    [onChange, setValue]
  );

  return (
    <ControlWithHelperText
      tooltipVariant="error"
      showHelperTextInTooltip={showHelperTextInTooltip}
      helperText={touched && error}
      open={tooltipOpen}
      renderControl={renderControlProps => (
        <TextField
          {...restProps}
          {...field}
          value={value ?? ''}
          helperText={renderControlProps.helperText}
          inputProps={{
            ...restProps.inputProps,
            ...renderControlProps.inputProps,
            onFocus: () => setTooltipOpen(true),
            onBlur: () => setTooltipOpen(false),
          }}
          SelectProps={{
            ...restProps.SelectProps,
            required: restProps.required,
          }}
          select
          onChange={handleChange}
          error={meta.touched && Boolean(meta.error)}
        >
          {renderOption && options.map((option, idx) => renderOption({ key: idx, option }))}
          {/* Typing value property as any so that it can be passed to MenuItem. More info can be found
             under this MUI Issue https://github.com/mui/material-ui/issues/14286 where this approach is
             mentioned as the best one. */}
          {!renderOption &&
            options.map(option => (
              <MenuItem key={option.label} value={option.value as string}>
                {option.label}
              </MenuItem>
            ))}
        </TextField>
      )}
    />
  );
}
