import type {
  AutocompleteProps,
  AutocompleteTextFieldProps,
  ControlWithHelperTextProps,
  UseAutocompleteProps,
} from '@cmg/design-system';
import { Autocomplete, ControlWithHelperText } from '@cmg/design-system';
import { useField } from 'formik';
import React, { useCallback } from 'react';

export type FormikAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'TextFieldProps' | 'value' | 'onChange'
> &
  Pick<ControlWithHelperTextProps, 'showHelperTextInTooltip'> &
  Partial<Pick<AutocompleteTextFieldProps, 'label' | 'placeholder' | 'required'>> & {
    /**
     * Name attribute of the `input` element.
     *
     * This is required.
     */
    name: string;

    ref?: React.ForwardedRef<HTMLUListElement>;

    /**
     * A function to parse the Formik value to a format suitable for use with the Autocomplete component.
     * @param value - The value from Formik.
     * @param options - The available options for the Autocomplete component.
     * @returns The parsed value for use with the Autocomplete component.
     */
    parseFormikValue?: (
      value: unknown,
      options: readonly T[]
    ) => UseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['value'];

    /**
     * A function to parse the Autocomplete value back to a format suitable for use with Formik.
     * @param value - The value from the Autocomplete component.
     * @returns The parsed value for use with Formik.
     */
    parseToFormikValue?: (value: T | undefined | null) => unknown;

    onChange?: (value: T) => void;

    TextFieldProps?: Pick<AutocompleteTextFieldProps, 'InputProps'>;
  };

// Exported for testing
export function defaultParseFormikValue<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | false = false,
  FreeSolo extends boolean | undefined = undefined
>(value: unknown) {
  return value as UseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['value'];
}

// Exported for testing
export function defaultParseToFormikValue<T>(value: T | undefined | null) {
  return value;
}

/**
 * A Formik-compatible wrapper for the Autocomplete component.
 * @returns  JSX.Element
 */
export function FormikAutocompleteField<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | false = false,
  FreeSolo extends boolean | undefined = undefined
>({
  name,
  options,
  label,
  placeholder,
  required,
  defaultValue,
  onChange,
  showHelperTextInTooltip,
  parseFormikValue = defaultParseFormikValue<T, Multiple, DisableClearable, FreeSolo>,
  parseToFormikValue = defaultParseToFormikValue<T>,
  TextFieldProps,
  ...restProps
}: FormikAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
  const [field, meta, helpers] = useField<unknown | null | undefined>(name);

  const handleChange = useCallback(
    (
      event: React.SyntheticEvent,
      value: UseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['value'],
      reason: string
    ) => {
      helpers.setTouched(true);
      if (reason === 'clear') {
        helpers.setValue(null, true);
        onChange?.(value as T);
        return;
      }
      const parsedValue = parseToFormikValue(value as T);
      helpers.setValue(parsedValue, true);
      onChange?.(value as T);
    },
    [helpers, parseToFormikValue, onChange]
  );

  const value = parseFormikValue(field.value, options);

  return (
    <ControlWithHelperText
      tooltipVariant="error"
      showHelperTextInTooltip={showHelperTextInTooltip}
      helperText={meta.touched && meta.error}
      renderControl={renderControlProps => (
        <Autocomplete
          TextFieldProps={{
            ...TextFieldProps,
            name,
            label,
            placeholder,
            required,
            error: meta.touched && Boolean(meta.error),
            helperText: renderControlProps.helperText,
          }}
          value={value}
          onChange={handleChange}
          options={options}
          {...restProps}
        />
      )}
    />
  );
}
