import { apiTypes, reduxUtil } from '@cmg/common';
import { SnackbarManager } from '@cmg/design-system';
import { History } from 'history';
import { AnyAction, combineReducers } from 'redux';
import { show } from 'redux-modal';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as accountApiClient from '../../../../common/api/accountApiClient';
import systemManagementApiClient, {
  DeleteApiKeyResponse,
  GetApiKeyResponse,
  PutRegenerateApiKeyResponse,
} from '../../../../common/api/systemManagementApiClient';
import { RootState } from '../../../../common/redux/rootReducer';
import routeFactory from '../../../../common/util/routeFactory';
import { ApiKey } from '../../../../types/domain/api-keys/api-key';
import { selectSelfSubdomain } from '../../../shared/ducks';
import { selectAccountSubdomain } from '../../shared/ducks';
import { API_KEY_MODAL_ID } from '../shared/ApiKeyModal';

const createReducer = reduxUtil.createReducer;

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  FETCH_API_KEY_REQUEST = 'global_management/api-keys/FETCH_API_KEY_REQUEST',
  FETCH_API_KEY_SUCCESS = 'global_management/api-keys/FETCH_API_KEY_SUCCESS',
  FETCH_API_KEY_FAILURE = 'global_management/api-keys/FETCH_API_KEY_FAILURE',
  REGENERATE_API_KEY_REQUEST = 'global_management/api-keys/REGENERATE_API_KEY_REQUEST',
  REGENERATE_API_KEY_SUCCESS = 'global_management/api-keys/REGENERATE_API_KEY_SUCCESS',
  REGENERATE_API_KEY_FAILURE = 'global_management/api-keys/REGENERATE_API_KEY_FAILURE',
  REVOKE_API_KEY_REQUEST = 'global_management/api-keys/REVOKE_API_KEY_REQUEST',
  REVOKE_API_KEY_SUCCESS = 'global_management/api-keys/REVOKE_API_KEY_SUCCESS',
  REVOKE_API_KEY_FAILURE = 'global_management/api-keys/REVOKE_API_KEY_FAILURE',
  RESET_API_KEY_STATE = 'global_management/api-keys/RESET_API_KEY_STATE',
  RESET_API_KEY_ERRORS = 'global_management/api-keys/RESET_API_KEY_ERRORS',
}

/**
 * ACTION CREATORS
 */

export const fetchApiKey = (params: { apiKeyId: string }) => ({
  type: ActionTypes.FETCH_API_KEY_REQUEST,
  payload: { ...params },
});

export const fetchApiKeySuccess = (params: { apiKey: ApiKey }) => ({
  type: ActionTypes.FETCH_API_KEY_SUCCESS,
  payload: {
    apiKey: params.apiKey,
  },
});

export const fetchApiKeyFailure = ({ error }) => ({
  type: ActionTypes.FETCH_API_KEY_FAILURE,
  payload: {
    error,
  },
});

export const regenerateApiKey = (params: { apiKeyId: string }) => ({
  type: ActionTypes.REGENERATE_API_KEY_REQUEST,
  payload: { ...params },
});

export const regenerateApiKeySuccess = () => ({
  type: ActionTypes.REGENERATE_API_KEY_SUCCESS,
});

export const regenerateApiKeyFailure = ({ error }) => ({
  type: ActionTypes.REGENERATE_API_KEY_FAILURE,
  payload: {
    error,
  },
});

export const revokeApiKey = (params: { apiKeyId: string; history: History<any> }) => ({
  type: ActionTypes.REVOKE_API_KEY_REQUEST,
  payload: { ...params },
});

export const revokeApiKeySuccess = () => ({
  type: ActionTypes.REVOKE_API_KEY_SUCCESS,
});

export const revokeApiKeyFailure = ({ error }) => ({
  type: ActionTypes.REVOKE_API_KEY_FAILURE,
  payload: {
    error,
  },
});

export const resetApiKeyDetailState = () => ({
  type: ActionTypes.RESET_API_KEY_STATE,
});

export const resetApiKeyErrors = () => ({
  type: ActionTypes.RESET_API_KEY_ERRORS,
});

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.FETCH_API_KEY_REQUEST]: ReturnType<typeof fetchApiKey>;
  [ActionTypes.FETCH_API_KEY_SUCCESS]: ReturnType<typeof fetchApiKeySuccess>;
  [ActionTypes.FETCH_API_KEY_FAILURE]: ReturnType<typeof fetchApiKeyFailure>;
  [ActionTypes.REGENERATE_API_KEY_REQUEST]: ReturnType<typeof regenerateApiKey>;
  [ActionTypes.REGENERATE_API_KEY_SUCCESS]: ReturnType<typeof regenerateApiKeySuccess>;
  [ActionTypes.REGENERATE_API_KEY_FAILURE]: ReturnType<typeof regenerateApiKeyFailure>;
  [ActionTypes.REVOKE_API_KEY_REQUEST]: ReturnType<typeof revokeApiKey>;
  [ActionTypes.REVOKE_API_KEY_SUCCESS]: ReturnType<typeof revokeApiKeySuccess>;
  [ActionTypes.REVOKE_API_KEY_FAILURE]: ReturnType<typeof revokeApiKeyFailure>;
  [ActionTypes.RESET_API_KEY_STATE]: ReturnType<typeof resetApiKeyDetailState>;
  [ActionTypes.RESET_API_KEY_ERRORS]: ReturnType<typeof resetApiKeyErrors>;
};

/**
 * REDUCERS
 */
export type ReducerState = {
  error: apiTypes.GenericServerError | null;
  regenerating: boolean;
  loading: boolean;
  apiKey: ApiKey | null;
};

export const initialState = {
  error: null,
  regenerating: false,
  loading: false,
  apiKey: null,
};

const errorReducer = createReducer<ReducerState['error'], Actions>(initialState.error, {
  [ActionTypes.FETCH_API_KEY_REQUEST]: () => null,
  [ActionTypes.FETCH_API_KEY_SUCCESS]: () => null,
  [ActionTypes.FETCH_API_KEY_FAILURE]: (curState, { payload }) => payload.error,
  [ActionTypes.REGENERATE_API_KEY_FAILURE]: (curState, { payload }) => payload.error,
});

const loadingReducer = createReducer<ReducerState['loading'], Actions>(initialState.loading, {
  [ActionTypes.FETCH_API_KEY_REQUEST]: () => true,
  [ActionTypes.FETCH_API_KEY_SUCCESS]: () => false,
  [ActionTypes.FETCH_API_KEY_FAILURE]: () => false,
});

const regeneratingReducer = createReducer<ReducerState['regenerating'], Actions>(
  initialState.regenerating,
  {
    [ActionTypes.REGENERATE_API_KEY_REQUEST]: () => true,
    [ActionTypes.REGENERATE_API_KEY_SUCCESS]: () => false,
    [ActionTypes.REGENERATE_API_KEY_FAILURE]: () => false,
  }
);

const apiKeyReducer = createReducer<ReducerState['apiKey'], Actions>(initialState.apiKey, {
  [ActionTypes.FETCH_API_KEY_SUCCESS]: (curState, { payload }) => payload.apiKey,
});

// This reducer acts on the entire state of this duck. Has access to duck state and must return duck state.
const crossSliceReducer = createReducer<ReducerState, Actions>(initialState, {
  [ActionTypes.RESET_API_KEY_STATE]: () => ({ ...initialState }),
  [ActionTypes.RESET_API_KEY_ERRORS]: curState => ({ ...curState, error: null }),
});

const combinedReducers = combineReducers<ReducerState>({
  error: errorReducer,
  loading: loadingReducer,
  regenerating: regeneratingReducer,
  apiKey: apiKeyReducer,
});

// Combines our individual slice reducers and the cross slice reducer.
export default function duckReducer(state: ReducerState = initialState, action: AnyAction) {
  const intermediateState = combinedReducers(state, action);
  return crossSliceReducer(intermediateState, action);
}

/**
 * SELECTORS
 */
const selectState = (state: RootState): ReducerState => state.adminAccountApiKeyDetail;

export const selectApiKey = state => selectState(state).apiKey;
export const selectRegenerating = state => selectState(state).regenerating;
export const selectLoading = state => selectState(state).loading;
export const selectError = state => selectState(state).error;

/**
 * SAGAS
 */
export function* fetchApiKeySaga({
  payload,
}: Actions[ActionTypes.FETCH_API_KEY_REQUEST]): SagaIterator {
  const { apiKeyId } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: accountApiClient.GetMyApiKeyResponse | GetApiKeyResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.getMyApiKey, apiKeyId);
  } else {
    response = yield call(systemManagementApiClient.admin.getApiKey, accountSubdomain, apiKeyId);
  }

  if (response.ok) {
    const apiKey = response.data;
    yield put(fetchApiKeySuccess({ apiKey }));
  } else {
    yield put(fetchApiKeyFailure({ error: response.data.error }));
  }
}

export function* regenerateApiKeySaga({
  payload,
}: Actions[ActionTypes.REGENERATE_API_KEY_REQUEST]): SagaIterator {
  const { apiKeyId } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: accountApiClient.PutRegenerateMyApiKeyResponse | PutRegenerateApiKeyResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.regenerateMyApiKey, apiKeyId);
  } else {
    response = yield call(
      systemManagementApiClient.admin.regenerateApiKey,
      accountSubdomain,
      apiKeyId
    );
  }

  if (response.ok) {
    const { accessToken } = response.data;
    yield put(regenerateApiKeySuccess());
    yield put(show(API_KEY_MODAL_ID, { accessToken }));
  } else {
    yield put(regenerateApiKeyFailure({ error: response.data.error }));
  }
}

export function* revokeApiKeySaga({
  payload,
}: Actions[ActionTypes.REVOKE_API_KEY_REQUEST]): SagaIterator {
  const { apiKeyId, history } = payload;
  const selfSubdomain = yield select(selectSelfSubdomain);
  const accountSubdomain = yield select(selectAccountSubdomain);

  let response: accountApiClient.DeleteMyApiKeyResponse | DeleteApiKeyResponse;
  if (accountSubdomain === selfSubdomain) {
    response = yield call(accountApiClient.revokeMyApiKey, apiKeyId);
  } else {
    response = yield call(systemManagementApiClient.admin.revokeApiKey, accountSubdomain, apiKeyId);
  }

  if (response.ok) {
    yield put(revokeApiKeySuccess());
    history.push(routeFactory.accountApiKeys.getUrlPath({ accountSubdomain }));
    SnackbarManager.success('Successfully revoked API key.');
  } else {
    yield put(revokeApiKeyFailure({ error: response.data.error }));
  }
}

export function* adminAccountApiKeyDetailSaga() {
  yield takeLatest<Actions[ActionTypes.FETCH_API_KEY_REQUEST]>(
    ActionTypes.FETCH_API_KEY_REQUEST,
    fetchApiKeySaga
  );

  yield takeLatest<Actions[ActionTypes.REGENERATE_API_KEY_REQUEST]>(
    ActionTypes.REGENERATE_API_KEY_REQUEST,
    regenerateApiKeySaga
  );

  yield takeLatest<Actions[ActionTypes.REVOKE_API_KEY_REQUEST]>(
    ActionTypes.REVOKE_API_KEY_REQUEST,
    revokeApiKeySaga
  );
}
