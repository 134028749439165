import { TraitCode as Trait } from '@capital-markets-gateway/api-client-identity';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

export { Trait };

export const traitLabels: { [key in Trait]: string } = {
  [Trait.API_KEYS]: 'API KEYS',
  [Trait.MY_DATA_LAB]: 'MY DATALAB',
  [Trait.DATALAB_API]: 'DATALAB API',
  [Trait.DATALAB_UI]: 'DATALAB UI',
  [Trait.DATALAB_UI_LIVE_FILED]: 'DATALAB UI LIVE FILED',
  [Trait.XC_CALENDAR]: 'XC CALENDAR',
  [Trait.XC_INDICATE]: 'XC INDICATE',
  [Trait.XC_CREATE]: 'XC CREATE',
  [Trait.MY_SECTORS]: 'MY SECTORS',
  [Trait.INTERNATIONAL]: 'INTERNATIONAL',
  [Trait.CONVERTS]: 'CONVERTS',
  [Trait.ATM]: 'ATM',
  [Trait.ATM_SELLDOWN]: 'ATM SELLDOWN',
  [Trait.INTERNATIONAL_OFFERING_TYPES]: 'INTERNATIONAL OFFERING TYPES',
  [Trait.XC_OFF_PLATFORM_OFFERINGS]: 'XC OFF PLATFORM OFFERINGS',
  [Trait.XC_IOI_NOTES]: 'XC IOI NOTES',
  [Trait.XC_TEST_OFFERINGS_IN_CALENDAR]: 'XC TEST OFFERINGS IN CALENDAR',
  [Trait.CERTIFICATE_LIBRARY]: 'CERTIFICATE LIBRARY',
  [Trait.CERTIFICATE_REVIEW]: 'CERTIFICATE REVIEW',
  [Trait.XC_CREATE_INTERNATIONAL]: 'XC CREATE INTERNATIONAL OFFERINGS',
  [Trait.DL_EXCEL_DOWNLOAD]: 'DL EXCEL DOWNLOAD',
};

// Options for full list of traits available to buy side accounts
export const traitOptionsBuySide = sortBy(
  [
    {
      value: Trait.API_KEYS,
      label: traitLabels[Trait.API_KEYS],
    },
    {
      value: Trait.DATALAB_API,
      label: traitLabels[Trait.DATALAB_API],
    },
    {
      value: Trait.DATALAB_UI,
      label: traitLabels[Trait.DATALAB_UI],
    },
    {
      value: Trait.DATALAB_UI_LIVE_FILED,
      label: traitLabels[Trait.DATALAB_UI_LIVE_FILED],
    },
    {
      value: Trait.MY_DATA_LAB,
      label: traitLabels[Trait.MY_DATA_LAB],
    },
    {
      value: Trait.DL_EXCEL_DOWNLOAD,
      label: traitLabels[Trait.DL_EXCEL_DOWNLOAD],
    },
    {
      value: Trait.XC_CALENDAR,
      label: traitLabels[Trait.XC_CALENDAR],
    },
    {
      value: Trait.XC_INDICATE,
      label: traitLabels[Trait.XC_INDICATE],
    },
    {
      value: Trait.MY_SECTORS,
      label: traitLabels[Trait.MY_SECTORS],
    },
    {
      value: Trait.INTERNATIONAL,
      label: traitLabels[Trait.INTERNATIONAL],
    },
    {
      value: Trait.CONVERTS,
      label: traitLabels[Trait.CONVERTS],
    },
    {
      value: Trait.ATM,
      label: traitLabels[Trait.ATM],
    },
    {
      value: Trait.ATM_SELLDOWN,
      label: traitLabels[Trait.ATM_SELLDOWN],
    },
    {
      value: Trait.INTERNATIONAL_OFFERING_TYPES,
      label: traitLabels[Trait.INTERNATIONAL_OFFERING_TYPES],
    },
    {
      value: Trait.XC_OFF_PLATFORM_OFFERINGS,
      label: traitLabels[Trait.XC_OFF_PLATFORM_OFFERINGS],
    },
    {
      value: Trait.XC_TEST_OFFERINGS_IN_CALENDAR,
      label: traitLabels[Trait.XC_TEST_OFFERINGS_IN_CALENDAR],
    },
  ],
  ['value']
);

// Options for full list of traits available to sell side accounts
export const traitOptionsSellSide = sortBy(
  [
    {
      value: Trait.API_KEYS,
      label: traitLabels[Trait.API_KEYS],
    },
    {
      value: Trait.DATALAB_API,
      label: traitLabels[Trait.DATALAB_API],
    },
    {
      value: Trait.DATALAB_UI,
      label: traitLabels[Trait.DATALAB_UI],
    },
    {
      value: Trait.DATALAB_UI_LIVE_FILED,
      label: traitLabels[Trait.DATALAB_UI_LIVE_FILED],
    },
    {
      value: Trait.DL_EXCEL_DOWNLOAD,
      label: traitLabels[Trait.DL_EXCEL_DOWNLOAD],
    },
    {
      value: Trait.XC_CALENDAR,
      label: traitLabels[Trait.XC_CALENDAR],
    },
    {
      value: Trait.XC_CREATE,
      label: traitLabels[Trait.XC_CREATE],
    },
    {
      value: Trait.MY_SECTORS,
      label: traitLabels[Trait.MY_SECTORS],
    },
    {
      value: Trait.INTERNATIONAL,
      label: traitLabels[Trait.INTERNATIONAL],
    },
    {
      value: Trait.CONVERTS,
      label: traitLabels[Trait.CONVERTS],
    },
    {
      value: Trait.ATM,
      label: traitLabels[Trait.ATM],
    },
    {
      value: Trait.ATM_SELLDOWN,
      label: traitLabels[Trait.ATM_SELLDOWN],
    },
    {
      value: Trait.INTERNATIONAL_OFFERING_TYPES,
      label: traitLabels[Trait.INTERNATIONAL_OFFERING_TYPES],
    },
    {
      value: Trait.XC_OFF_PLATFORM_OFFERINGS,
      label: traitLabels[Trait.XC_OFF_PLATFORM_OFFERINGS],
    },
    {
      value: Trait.XC_IOI_NOTES,
      label: traitLabels[Trait.XC_IOI_NOTES],
    },
    {
      value: Trait.XC_TEST_OFFERINGS_IN_CALENDAR,
      label: traitLabels[Trait.XC_TEST_OFFERINGS_IN_CALENDAR],
    },
    {
      value: Trait.CERTIFICATE_LIBRARY,
      label: traitLabels[Trait.CERTIFICATE_LIBRARY],
    },
    {
      value: Trait.CERTIFICATE_REVIEW,
      label: traitLabels[Trait.CERTIFICATE_REVIEW],
    },
  ],
  ['value']
);

// Options for full list of traits for both account types
export const traitOptionsAll = uniqBy(
  sortBy([...traitOptionsBuySide, ...traitOptionsSellSide], ['value']),
  'value'
);
