import type { ControlWithHelperTextProps, NumberFieldProps } from '@cmg/design-system';
import { ControlWithHelperText, NumberField } from '@cmg/design-system';
import { useField } from 'formik';
import React from 'react';

export type FormikNumberFieldProps = Omit<
  NumberFieldProps,
  'name' | 'value' | 'onChange' | 'onInputChange' | 'error' | 'helperText'
> &
  Pick<ControlWithHelperTextProps, 'showHelperTextInTooltip'> & {
    name: string;
    onChange?: (value: number | bigint | null) => void;
  };

export function FormikNumberField({
  name,
  showHelperTextInTooltip,
  onChange,
  ...props
}: FormikNumberFieldProps): JSX.Element {
  const [field, meta, helpers] = useField<number | bigint | null>(name);
  const { onChange: _onChange, ...fieldProps } = field;
  const { value, error, touched } = meta;
  const { setValue } = helpers;

  const handleChange = React.useCallback(
    (value: number | bigint | null) => {
      setValue(value);
      onChange?.(value);
    },
    [setValue, onChange]
  );

  return (
    <ControlWithHelperText
      tooltipVariant="error"
      showHelperTextInTooltip={showHelperTextInTooltip}
      helperText={touched && error}
      renderControl={renderControlProps => (
        <NumberField
          {...props}
          {...fieldProps}
          name={name}
          value={value}
          helperText={renderControlProps.helperText}
          onInputChange={handleChange}
          inputProps={{
            ...props.inputProps,
            ...renderControlProps.inputProps,
          }}
          error={touched && Boolean(meta.error)}
        />
      )}
    />
  );
}
