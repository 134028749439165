import { Banner, Icon, PrimaryButton, TextInputField } from '@cmg/common';
import { forgotPasswordScreenSelector } from '@cmg/e2e-selectors';
import { Form, FormikProps, withFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { SButtonsSection, SFormSection, SHeading, SMessage } from './ForgotPasswordForm.styles';

// exported for testing only
export const ForgotPasswordSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Email must be a valid email address'),
});

type ForgotPasswordFormValues = {
  email: string;
};

type OwnProps = {
  onSubmit: (values: ForgotPasswordFormValues) => void;
  error: boolean;
  success: boolean;
  submitting: boolean;
};
type Props = OwnProps & FormikProps<ForgotPasswordFormValues>;

export class ForgotPasswordFormComponent extends React.Component<Props> {
  render() {
    const { error, submitting, success } = this.props;

    if (success) {
      return (
        <SHeading>
          Password reset email sent. Follow the directions in the email to reset your password.If
          you don’t hear from us in a few minutes, please check your spam folder or email{' '}
          <a href="mailto:support@cmgx.io">support@cmgx.io</a>
        </SHeading>
      );
    }

    return (
      <Form data-test-id={forgotPasswordScreenSelector.testId}>
        <SMessage>
          To reset your password, enter the email address you use to sign in to CMG.
        </SMessage>
        <SFormSection>
          <TextInputField
            size="large"
            prefix={<Icon name="envelope" />}
            name="email"
            placeholder="Your Email"
            fullWidth
          />
        </SFormSection>
        <SButtonsSection>
          <PrimaryButton
            loading={submitting}
            type="submit"
            size="large"
            fullWidth
            variant="high-contrast"
          >
            Reset Password
          </PrimaryButton>
        </SButtonsSection>

        {error && (
          <Banner variant="error">
            There was an issue resetting your password, please try again.
          </Banner>
        )}
      </Form>
    );
  }
}

export default withFormik<OwnProps, ForgotPasswordFormValues>({
  enableReinitialize: false,
  isInitialValid: false,
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: ForgotPasswordSchema,
  mapPropsToValues: () => ({
    email: '',
  }),
  handleSubmit: (values, formikBag) => formikBag.props.onSubmit(values),
})(ForgotPasswordFormComponent);
